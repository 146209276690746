import * as React from "react"

import axios from'axios'


const PageAxios=(props)=>{
  const[data,setData]=React.useState()

  React.useEffect(()=>{
    const getData = async () => {
      try {
        setData(await axios.get('https://jhlm.pattyhendrix.com/'))
      } catch (error) {console.log('[[ ERRORRR ]]',error)}
    }
    getData()
  },[])


  React.useEffect(()=>{
    console.log({data})
  },[data])


  return <>
    {data?JSON.stringify(data):'no data :('}
  </>
}

export default PageAxios
